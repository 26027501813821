<div class="map-container">
    <div id="map"></div>
    <div class="map-legend">
        <div class="map-buttons">
            <button v-if="selectedRegion.district" :disabled="!selectedRegion.county" @click="changeMap()">
                &lsaquo; Vissza
            </button>
            <button class="search-button" @click="isSearch = true">
                <span>Kereső</span>
                <svg width="100%" height="100%" viewBox="0 0 24 24" class="search">
                    <path fill-rule="evenodd" d="M11.793 13.553c-1.221.869-2.714 1.38-4.327 1.38A7.47 7.47 0 0 1 0 7.467 7.47 7.47 0 0 1 7.467 0a7.47 7.47 0 0 1 7.467 7.467c0 1.613-.511 3.106-1.38 4.326L20 18.24 18.24 20l-6.447-6.447zm.651-6.086a4.98 4.98 0 0 1-4.978 4.978 4.98 4.98 0 0 1-4.978-4.978 4.98 4.98 0 0 1 4.978-4.978 4.98 4.98 0 0 1 4.978 4.978z" />
                </svg>
            </button>
        </div>
        <div class="map-info">
            <p v-if="selectedRegion.county && !voteData.selected" class="area">
                <b>{{ selectedRegion.county.name }}</b>
            </p>
            <p v-if="voteData.selected" class="area">
                <b>{{ voteData.selected.shortName }}</b> - {{ voteData.selected.central }}
            </p>
            <div v-if="selectedRegion.county && !voteData.selected" class="map-buttons">
                <button
                        class="button map-button-fw"
                        :disabled="!selectedRegion.county"
                        @click="changeMap()"
                >
                    Tovább a {{ selectedRegion.countyLayer.feature.properties.name }}i adatokra &rsaquo;
                </button>
            </div>
            <div
                    v-if="selectedRegion.county && !voteData.selected"
                    class="map-hint"
            >
                <svg
                        viewBox="0 0 10 10"
                        fill-rule="evenodd"
                        stroke-linejoin="round"
                        stroke-miterlimit="2"
                        class="map-hint--icon"
                >
                    <path d="M10 5.002v-.036a4.99 4.99 0 0 0-.303-1.684l-.009-.024-.034-.088-.044-.108A5.01 5.01 0 0 0 5.039 0h-.037-.039c-.634.005-1.239.127-1.796.346l-.111.045-.11.048C1.221 1.219.016 2.95 0 4.963v.035.038c.005.633.126 1.239.346 1.795l.045.111C1.145 8.727 2.908 9.984 4.966 10h.032.037c.548-.004 1.075-.095 1.567-.262l.113-.04a5.01 5.01 0 0 0 3.285-4.665L10 5.002zM5.139 1.574c.487-.004.9.415.898.911-.002.501-.405.908-.899.908s-.9-.406-.902-.905a.91.91 0 0 1 .903-.914zm1.214 6.244c-.312.346-.693.56-1.165.592-.257.018-.515.034-.769-.035-.465-.127-.776-.552-.716-1.03l.228-1.41.209-1.225a.71.71 0 0 0-.005-.204.23.23 0 0 0-.201-.211c-.067-.013-.139-.005-.204-.023-.092-.025-.192-.049-.268-.102-.125-.088-.1-.266.041-.326a.62.62 0 0 1 .228-.044l.882-.002V3.8h.902c.197.001.289.085.331.276.036.167-.011.323-.041.483l-.372 2.022-.107.626c-.006.035-.004.072-.004.108.005.41.264.465.561.346.084-.034.158-.091.239-.134.139-.075.213-.077.268-.012.067.078.061.194-.037.303z" fill-rule="nonzero" />
                </svg>
                <p class="map-hint--text">
                    A választókerületi eredményeket a megye kiválasztását követően érheti el.
                </p>
            </div>
            <div
                    v-if="selectedRegion.county && voteData.selected"
                    class="map-info--candidates"
            >
                <p v-for="candidate in orderCandidates(voteData.selected.candidates)" :key="candidate.name">
                    <b v-if="candidate.percent > 0" class="candidate--name">({{ candidate.percent }}%)</b>
                    <b class="candidate--name">{{ candidate.name }}</b>
                    <span class="candidate--info"> - </span>
                    <b class="candidate--info">Jelölő szervezet:</b>
                    <span class="candidate--info">{{ Object.values(candidate.nominatedBy).join(', ') }}</span>
                    <span class="candidate--info"> , </span>
                    <b class="candidate--info">Támogató pártok:</b>
                    <span class="candidate--info">{{ Object.values(candidate.supportedBy).join(', ') }}</span>
                    <span class="candidate--info"> , </span>
                    <b class="candidate--info">Választott frakció:</b>
                    <span v-if="candidate.faction1" class="candidate--info">{{ candidate.faction1 }}</span>
                    <span v-if="candidate.faction2" class="candidate--info">, {{ candidate.faction2 }}</span>
                </p>
            </div>
        </div>
    </div>
    <div v-show="isSearch" class="map-search">
        <div class="close-button">
            <button class="close-icon" @click="isSearch = false" type="button"/>
        </div>
        <div class="search-box">
            <p><b>Választókerületi központ keresése</b></p>
            <p>
                <v-select
                        v-if="voteData.data"
                        :options="voteData.data"
                        placeholder="Településnév"
                        label="central"
                        @option:selected="searchMap"
                >
                    <template v-slot:option="option">
                        {{ option.central }} - {{ option.shortName }}
                    </template>
                    <template v-slot:no-options="">
                        Nincs találat!
                    </template>
                </v-select>
            </p>
        </div>
    </div>
</div>
