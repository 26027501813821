import { createApp, h } from 'vue'
import LeafletPlugin from './plugins/leaflet'
import Map from './components/Map'

createApp({
  components: [
    Map,
  ],
  render: ()=>h(Map),
})
    .use(LeafletPlugin)
    .mount('#app')
